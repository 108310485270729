<template>
    <b-tabs class="tabs-pills">
        <b-tab :title="'General|Général'|tr" class="py-3 px-1">
            <h4 class="mb-4" v-tr>Page Settings|Paramètres de la page</h4>

            <e-translation-input label="Page Name|Nom de la page" v-model="currentPage.name">
                <template #form-help>
                    <div class="form-help mt-2" v-tr>
                        The page name will be used for navigation links|
                        Le nom de la page sera utilisé pour les liens de navigation
                    </div>
                </template>
            </e-translation-input>

            <b-form-group :label="'Page Icon|Icône de la page'|tr">
                <input-glyph v-model="currentPage.glyph"/>
            </b-form-group>

            <hr>

            <e-translation-input v-if="!currentPage.homePage" label="Permalink|Permalien" v-model="currentPage.permalink">
                <template #label-info>
                    <e-hint text="Important field for the SEO. The permalink is the text that will display in the URL: max 5 words, no special characters, no spaces, only '-' to separate words|
                                                Valeur importante pour le SEO. Le permalien est le texte qui s'affichera dans l'URL : maximum 5 mots, pas de caractères spéciaux, pas d'espaces, seulement '-' pour séparer les mots"/>
                </template>
                <template #form-help>
                    <div class="form-help mt-2">https://{{cmsWebsite.domain}}/{{tr(currentPage.permalink)}}</div>
                    <div class="form-help mt-2" v-tr>No Space and No Special Characters|Aucun espace ni caractères spéciaux</div>
                </template>
                <template #append>
                    <b-btn variant="light" @click="updatePermalink">
                        <i class="fas fa-sync"/>
                    </b-btn>
                </template>
            </e-translation-input>

            <b-form-group v-else>
                <b-checkbox v-model="currentPage.homePage" disabled>
                    <span v-tr>Home Page|Page d'accueil</span>
                </b-checkbox>
            </b-form-group>

            <b-form-group>
                <b-checkbox v-model="currentPage.template" disabled>
                    <span v-tr>Template Only|Gabarit seulement</span>
                </b-checkbox>
            </b-form-group>

<!--            <b-form-group>-->
<!--                <b-checkbox v-model="currentPage.navigationBehavior" value="visible" unchecked-value="hidden">-->
<!--                    <span v-tr>Show in Navigation|Montrer dans la navigation</span>-->
<!--                </b-checkbox>-->
<!--            </b-form-group>-->
        </b-tab>
        <b-tab :title="'SEO'|tr" class="py-3 px-1">
            <h4 class="mb-4" v-tr>Search Engine Optimization (SEO)|Optimisation des moteurs de recherche (SEO)</h4>

            <e-translation-input v-model="currentPage.meta.title" @change="currentPage.syncSocial()" label="SEO page Title|Titre de la page pour SEO"/>
            <hr>
            <e-translation-input v-model="currentPage.meta.description" @change="currentPage.syncSocial()" type="textarea" label="SEO page Description|Description de la page pour SEO"/>
            <b-form-group :label="'Search Engine Visibility|Visibility des moteurs de recherche'|tr">
                <b-select v-model="currentPage.meta.searchVisibility">
                    <option value="visible" v-tr>Visible to search engines|Visible par les moteurs de recherche</option>
                    <option value="hidden" v-tr>Hidden from search engines results|Caché des résultats des moteurs de recherche</option>
                </b-select>
            </b-form-group>
        </b-tab>
        <b-tab :title="'Soc. Media|Réseaux soc.'|tr" class="py-3 px-1">
            <h4 class="mb-4" v-tr>Social Media|Réseaux sociaux</h4>

            <b-form-group :label="'Social Image|Image aperçu'|tr">
                <input-image v-model="currentPage.meta.socialImage" size="xl"/>
            </b-form-group>

            <e-translation-input v-model="currentPage.meta.socialTitle" label="Social page Title|Titre pour réseaux sociaux" :readonly="currentPage.meta.socialTitle.sameAsSEO"/>
            <b-checkbox v-model="currentPage.meta.socialTitle.sameAsSEO"><span v-tr>Same as SEO page title|Identique au titre de la page SEO</span></b-checkbox>
            <hr>
            <e-translation-input v-model="currentPage.meta.socialDescription" type="textarea" label="Social page Description|Description page pour réseaux sociaux" :readonly="currentPage.meta.socialDescription.sameAsSEO"/>
            <b-checkbox v-model="currentPage.meta.socialDescription.sameAsSEO"><span v-tr>Same as SEO page title|Identique au titre de la page SEO</span></b-checkbox>
        </b-tab>
        <b-tab :title="'Advanced|Avancé'|tr" class="py-3 px-1">
            <sidebar-group title="Device Display|Affichage par appareil">
                <b-form-group>
                    <btn-checkbox v-model="currentPage.mediaQuery.desktop.visible" :title="'Select if visible on desktop device|Visibilité sur ordinateur'|tr">
                        <i class="fas fa-desktop" style="font-size: 16pt;"/>
                    </btn-checkbox>
                    <btn-checkbox v-model="currentPage.mediaQuery.tablet.visible" :title="'Select if visible on tablet device|Visibilité sur tablette'|tr">
                        <i class="fas fa-tablet-screen" style="font-size: 16pt;"/>
                    </btn-checkbox>
                    <btn-checkbox v-model="currentPage.mediaQuery.mobile.visible" :title="'Select if visible on mobile device|Visibilité sur mobile'|tr">
                        <i class="fas fa-mobile-iphone" style="font-size: 16pt;"/>
                    </btn-checkbox>
                    <btn-checkbox v-model="currentPage.mediaQuery.kiosk.visible" :title="'Select if visible on kiosk device|Visibilité sur kiosque'|tr">
                        <i class="fak fa-kiosk" style="font-size: 16pt;"/>
                    </btn-checkbox>
                </b-form-group>
                <div class="form-help mb-2" v-tr>You can enable/disable access to the page based on the user device|Vous pouvez activer ou désactiver l'accès à la page en fonction de l'appareil utilisé</div>

                <b-form-group :label="tr('Fallback to|Rediriger vers')">
                    <input-link v-model="currentPage.fallbackLink"/>
                </b-form-group>
            </sidebar-group>
        </b-tab>
    </b-tabs>
</template>

<script>
import SidebarGroup from "@/components/editor/properties/components/sidebar-group.vue";
import BtnCheckbox from "@/components/editor/properties/inputs/btn-checkbox.vue";
import InputGlyph from "@/components/editor/properties/inputs/input-glyph.vue";
import InputImage from "@/components/editor/properties/inputs/input-image.vue";
import InputLink from "@/components/editor/properties/inputs/input-link.vue";
import EHint from "../../../vue-components/components/e-hint.vue";

// page-settings-modal #hot-reload-debug
import ETranslationInput from "../../../vue-components/components/e-translation-input.vue";
import CmsHelper from "../../../vue-components/helpers/CmsHelper.js";

export default {
    name: `page-settings-modal`,
    components: {InputLink, BtnCheckbox, SidebarGroup, EHint, InputGlyph, InputImage, ETranslationInput},
    props: {
        value: {type: Object}
    },
    computed: {
        currentPage: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit(`input`, value);
            }
        }
    },
    methods: {
        updatePermalink() {
            for (let lang in this.newPage.name) {
                this.newPage.permalink[lang] = CmsHelper.textToPermalink(this.newPage.name[lang]);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
