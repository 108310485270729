<template>
    <vue-select class="select-cms-item" v-if="items" v-model="currentItem" :reduce="reducer" :placeholder="tr('Please select...|Merci de choisir...')" :getOptionLabel="i => tr(i.name)" :clearable="allowEmpty" :options="items" :append-to-body="appendToBody">
        <template #open-indicator>
            <i class="ml-auto fas fa-caret-down"></i>
        </template>
        <template v-slot:selected-option="option">
            <i class="fa-fw float-left pt-1 fas mr-1" :class="option.glyph"/>
            <div>
                <div class="f-500">{{ option.name|tr }}</div>
            </div>
        </template>
        <template v-slot:option="option">
            <div class="menu-option">
                <i class="fa-fw float-left fas pt-1 mr-1" :class="option.glyph"/>
                <div>
                    <div class="f-500">{{ option.name|tr }}</div>
                </div>
            </div>
        </template>
    </vue-select>
</template>

<script>
import NetworkV2 from "@/../vue-components/helpers/NetworkV2.js";
import VueSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

export default {
    name: `select-cms-item`,
    components: {VueSelect},
    props: {
        value: {type: [String, Object]},
        itemType: {type: String, default: null},
        returnEntireObject: {type: Boolean},
        useStore: {type: Boolean}, // optimization to use store instead of requesting network each time, for map editor
        allowEmpty: {type: Boolean, default: false},
        appendToBody: {type: Boolean}
    },
    data() {
        return {
            items: null
        }
    },
    computed: {
        currentItem: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit(`input`, value);
            }
        }
    },
    mounted() {
        this.loadItems();
    },
    methods: {
        reducer(item) {
            if (this.returnEntireObject) {
                return item;
            } else {
                return item.id;
            }
        },
        loadItems() {
            if (this.useStore && this.cmsItems) {
                if (this.itemType) {
                    this.items = this.cmsItems.filter(i => i.type === this.itemType);
                } else {
                    this.items = this.cmsItems;
                }
                return;
            }
            let url = `/cms/items/list/${this.dealerid}`;
            if (this.itemType) {
                url += `/${this.itemType}`;
            }
            NetworkV2.get(url).then(resp => {
                this.items = resp.data;
                this.loading = false;
            })
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
